import React , { useEffect, useState } from 'react';

import { graphql } from 'gatsby';
import Layout from "../components/layout"
import Form from "../components/form"
import CouponForm from "../components/coupon_form"

import Helmet from 'react-helmet'
import SocialProof from '../components/socialProof';
import Fade from 'react-reveal/Fade';
import PortableText from '@sanity/block-content-to-react'
import { FaArrowRight, FaMapMarkerAlt, FaTimes } from "react-icons/fa"
import $ from 'jquery';
import Image from 'gatsby-image'
// import couponBackground from "../images/couponBackground.webp"



function changeActive(e){
    e.preventDefault();
    $(".form").toggleClass("expanded");
    $('body').toggleClass('formExpanded');
  }

  function getUrlVars(){
    var vars = [], hash;
    if(typeof window !== 'undefined'){
        var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
        for(var i = 0; i < hashes.length; i++)
        {
            hash = hashes[i].split('=');
            vars.push(hash[0]);
            vars[hash[0]] = hash[1];
        }
    }
    return vars;
  }
  var city = getUrlVars()["city"];

  if (city !== undefined){
    let cityDash = city;
    cityDash = cityDash.replace(/-/g, ' ');

      var cityplace = " in " + cityDash;
      var citytitle = cityDash+"'s";

  }

  var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();

today = mm + '/' + dd + '/' + yyyy;

export const query = graphql`
query galleryQuery{
    sanityGallerypage{
        pagetitle
        topPageTitle
        pageIntroTitle
        slug {
            current
        }
        _rawPageIntro
        _rawFirstcopy
        coupon {
            title
            type
            coupontext
        }
        headerimage {
            asset {
                fluid(maxWidth: 1920) {
                    ...GatsbySanityImageFluid
                }
            }
        }
        serviceimage {
            asset {
                fluid(maxWidth: 1920) {
                  ...GatsbySanityImageFluid
                }
            }
        }
        gallery {
            images {
              asset {
                fluid {
                  src
                }
              }
            }
          }
}
    sanityCompanyInfo {
        companyname
        phone
        primarycolor{
            hex
            rgb{
                a
                r
                g
                b
            }
        }
        secondarycolor{
            hex
            rgb{
                a
                r
                g
                b
            }
        }
        tertiarycolor{hex}
        accentcolor{
            hex
            rgb{
                a
                r
                g
                b
            }
        }
        gradientcolor1{
            hex
            rgb{
                a
                r
                g
                b
            }
        }
        gradientcolor2{
            hex
            rgb{
                a
                r
                g
                b
            }
        }
        couponbackground{
            asset{
                fluid{
                    src
                }
            }
        }
    }
    allSanityCoupon{
        edges{
            node{
                title
                type
                hidecoupon
                coupontext
            }
        }
    }
}
`

export default ({ data }) => {
    // Define reviewsLink as a state variable
  const [reviewsLink, setReviewsLink] = useState('');

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const urlParams = Object.fromEntries(urlSearchParams.entries());

  
    // Update the links with URL parameters
    document.querySelectorAll('.serviceLink').forEach((link) => {
      const serviceSlug = link.getAttribute('data-service-slug');
      const modifiedLink = `${serviceSlug}?${Object.entries(urlParams).map(([key, value]) => `${key}=${value}`).join('&')}`;
      link.href = modifiedLink;
    });
  
     // Update the "See More Reviews" link
    const updatedReviewsLink = `/reviews${
      Object.keys(urlParams).length > 0
        ? '?' + Object.entries(urlParams).map(([key, value]) => `${key}=${value}`).join('&')
        : ''
    }`;

    // Set the reviewsLink state with the updated link
    setReviewsLink(updatedReviewsLink);

    console.log("reviewsLink:", reviewsLink); // Add this console.log statement
  
  }, []);
  return(
    <Layout>
    <Helmet>
    <title>{data.sanityCompanyInfo.companyname} | {data.sanityGallerypage.pagetitle}</title>   
      
    </Helmet>
    <div className="gallery">
    <div className="popupForm">
            <div className="popupForm">
                   <div className="form">
                      <div className="two_columns">
                      <div className="column1" style={{backgroundImage: 'url('+ data.sanityCompanyInfo.couponbackground.asset.fluid.src + ')'}}>
                          <div className="column-inner" style={{backgroundColor: data.sanityCompanyInfo.primarycolor.hex+"e3" }}>
                          <div className="coupon" style={{backgroundColor: "rgba(" + data.sanityCompanyInfo.primarycolor.rgb.r +","+ data.sanityCompanyInfo.primarycolor.rgb.g +","+ data.sanityCompanyInfo.primarycolor.rgb.b +","+ "0.7" +")"}}>
                          <div className="mobile-button formButton" style={{backgroundColor: data.sanityCompanyInfo.accentcolor.hex}}><a href={"tel:" + data.sanityCompanyInfo.phone}><span style={{color: data.sanityCompanyInfo.primarycolor.hex}}>Call Now</span> {data.sanityCompanyInfo.phone}</a></div>


                          <div className="scheduleText" style={{color: data.sanityCompanyInfo.tertiarycolor.hex}}>Schedule Today For</div>


                            <span className="coupon-title">{data.sanityGallerypage.coupon.title}</span>
                            <span className="coupon-type">{data.sanityGallerypage.coupon.type}</span>
                            <span className="coupon-text">{data.sanityGallerypage.coupon.coupontext}</span>
                            </div>
                          </div>

                        </div>
                        <div className="column2">
                          <div className="innerColumn">
                          <div className="desktop-button formButton" style={{backgroundColor: data.sanityCompanyInfo.accentcolor.hex}}><a href={"tel:" + data.sanityCompanyInfo.phone}><span style={{color: data.sanityCompanyInfo.primarycolor.hex}}>Call Now</span> {data.sanityCompanyInfo.phone}</a></div>

                            <h2>Schedule Service</h2>  
                            <p>Fill out the form below and we'll reach out to schedule your service appointment. </p>
                            <a className="closeForm" onClick={changeActive} style={{fill: "#fff", color: '#fff'}}><FaTimes /></a>
                            <Form />
                            </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        {/* <Fade bottom> */}
        <div className="pageHeader">
         <div className="columns">
        <div className="column1 column">
          <div className="column-inner">
          <Image
            style={{
              height: "100%",
              backgroundPosition: "center"
            }}
            fluid={data.sanityGallerypage.headerimage.asset.fluid} className="leftImage">

          </Image>
          </div>
        </div>
        <div className="column2 column">
          <div className="column-inner">
            <div className="location" style={{color: data.sanityCompanyInfo.accentcolor.hex}}><FaMapMarkerAlt /> {data.sanityGallerypage.topPageTitle} {cityplace}</div>
              <h1 style={{color: data.sanityCompanyInfo.primarycolor.hex}}>{data.sanityGallerypage.pageIntroTitle}</h1>

              <p style={{color: data.sanityCompanyInfo.primarycolor.hex}}>
                <PortableText blocks={data.sanityGallerypage._rawPageIntro} />

              </p>
              <div className="schedule-btn">
                <a onClick={changeActive} className="schedulebtn" style={{background: "linear-gradient( to right,"+ data.sanityCompanyInfo.gradientcolor1.hex + ","+ data.sanityCompanyInfo.gradientcolor2.hex +")"}}>Schedule Today for <span style={{color: data.sanityCompanyInfo.tertiarycolor.hex }}>{data.sanityGallerypage.coupon.title}</span> <FaArrowRight /></a>
              </div>
          </div>
        </div>
      </div>
    </div>
    {/* </Fade> */}
    <div className="galleryRow row">
        <h2>Photo Gallery</h2>
        <div className="inner">
        {data.sanityGallerypage.gallery.images.map(image => 
            <div className="gallery-image">
                <img src={image.asset.fluid.src} alt="" />
            </div>
           
        )}
        </div>
    </div>   
        <SocialProof />
        <div className="coupon-form-section" style={{height: "100%", backgroundImage: "url(" + data.sanityCompanyInfo.couponbackground.asset.fluid.src + ")"}} >
        <div className="background-overlay" style={{backgroundColor: "rgba(" + data.sanityCompanyInfo.primarycolor.rgb.r +","+ data.sanityCompanyInfo.primarycolor.rgb.g +","+ data.sanityCompanyInfo.primarycolor.rgb.b +","+ "0.7" +")"}}>

        {/* <Fade bottom> */}
            <div className="inner">
                <div className="columns">
                <div className="column1 column">
                    <div className="column-inner">
                    <div className="coupon" style={{backgroundColor: "rgba(" + data.sanityCompanyInfo.primarycolor.rgb.r +","+ data.sanityCompanyInfo.primarycolor.rgb.g +","+ data.sanityCompanyInfo.primarycolor.rgb.b +","+ "0.8" +")"}}>

                        <div className="scheduleText" style={{color: data.sanityCompanyInfo.tertiarycolor.hex}}>Schedule Today For</div>
                        <div className="couponOffer">{data.sanityGallerypage.coupon.title}</div>
                        <div className="couponType">{data.sanityGallerypage.coupon.type}</div>
                        <p className="couponInfo">{data.sanityGallerypage.coupon.coupontext}</p>
                    </div>
                    </div>
                </div>
                <div className="column2 column">
                    <div className="inner">
                    <h3>Don’t Wait All Day for Service!</h3>
                    <p>Fill out the form below and we’ll reach out to schedule your appointment.</p>
                    <CouponForm />

                    </div>
                </div>
                </div>
                </div>
            </div>
        {/* </Fade> */}
    </div>
    </div>
    <div className="sticky-mobile">
              <a onClick={changeActive} className="schedule-btn" style={{background: "linear-gradient( to right,"+ data.sanityCompanyInfo.gradientcolor1.hex + ","+ data.sanityCompanyInfo.gradientcolor2.hex +")"}}>Schedule Today for <span style={{color: data.sanityCompanyInfo.tertiarycolor.hex}}>{data.sanityGallerypage.coupon.title}</span> <FaArrowRight /></a>
            </div>
    
  </Layout>
)
}

